<template>
    <div>
      <b-row>
        <b-col lg="6" md="6" sm="6" class="heading-title">
          <h4>View Customers</h4>
          <b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
            <b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
            <b-breadcrumb-item active>View Customers</b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
        <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
      </b-row>
      <br />
      <b-card class="mb-4 w-100">
        <div class="d-flex justify-content-between align-items-center row py-3 gap-3 gap-md-0">
        <div class="col-md-3 product_status">
          <multiselect
                  v-model="first_name_selected"
                  :options="first_name_option"
                  placeholder="First Name"
                >
                </multiselect>
        </div>
        <div class="col-md-3 product_category">
          <multiselect
                    v-model="email_selected"
                    :options="email_option"
                    placeholder="Email"
                  >
                  </multiselect>
        </div>
        <div class="col-md-3 product_stock">
          <multiselect
                    v-model="phone_selected"
                    :options="phone_option"
                    placeholder="Contact No."
                  >
                  </multiselect>
        </div>
        <div class="col-md-3 product_stock">
          <div class=" d-flex justify-content-center button-text">
                <div class="p-1">
                  <b-button variant="primary" block @click="applyFilter()"  >
                    Apply</b-button
                  >
                </div>
                <div class="p-1">
                  <b-button variant="primary"  block @click="resetFilter()">
                    Reset</b-button
                  >
                </div>
                <div class="p-1">
                  <b-button
                    id="button_width"
                    variant="primary"
                    v-b-modal.modal-tall
                  >
                    <i class="mdi mdi-plus"></i>Add Customer </b-button
                  >
                </div>
            </div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center row py-3 gap-3 gap-md-0">
        <div class="col-md-3 product_status">
          <b-form-input
                  v-model="search"
                  id="search"
                  type="text"
                  placeholder="Search here"
                  v-on:keyup="key_search()"
          ></b-form-input>
        </div>
        <div class="col-md-4 product_status">
         
          <div class=" d-flex justify-content-end button-text">
           
            <div class="p-1">
              <b-form-select
              style="max-width: 90px;"
                    id="select_perpage"
                    v-model="pageSize"
                    :options="options"
                    @change="handlePageSizeChange($event)"
                  ></b-form-select>
            
              </div>
               
                <div class="p-1">
                  <b-button
                    id="button_width"
                    @click="ExportCustomers()"
                    variant="primary"
                  >
                    <i class="mdi mdi-export"></i>Export</b-button
                  >
                </div> 
                
          </div>
       </div>
      </div>
  
  
  
       <!--  <div class="venue-heading">
          <b-row>
            <b-col
              class="d-flex align-items-stretch"
              cols="12"
              lg="3"
              md="6"
              sm="12"
            >
              <div class="w-100">
                <multiselect
                  v-model="first_name_selected"
                  :options="first_name_option"
                  placeholder="First Name"
                >
                </multiselect>
              </div>
            </b-col>
            <b-col
              class="d-flex align-items-stretch"
              cols="12"
              lg="3"
              md="6"
              sm="12"
            >
              <div class="w-100">
                <div>
                  <multiselect
                    v-model="email_selected"
                    :options="email_option"
                    placeholder="Email"
                  >
                  </multiselect>
                </div>
              </div>
            </b-col>
            <b-col
              class="d-flex align-items-stretch"
              cols="12"
              lg="3"
              md="6"
              sm="12"
            >
              <div class="w-100">
                <div>
                  <multiselect
                    v-model="phone_selected"
                    :options="phone_option"
                    placeholder="Contact No."
                  >
                  </multiselect>
                </div>
              </div>
            </b-col>
            <b-col
              class="d-flex align-items-stretch"
              cols="12"
              lg="3"
              md="12"
              sm="12"
            >
              <div class="w-100 d-flex justify-content-end button-text">
                <div class="p-1">
                  <b-button variant="primary" @click="applyFilter()">
                    Apply</b-button
                  >
                </div>
                <div class="p-1">
                  <b-button variant="primary" @click="resetFilter()">
                    Reset</b-button
                  >
                </div>
                <div class="p-1">
                  <b-button
                    id="button_width"
                    @click="ExportCustomers()"
                    variant="primary"
                  >
                    <i class="mdi mdi-export"></i>Export</b-button
                  >
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
  
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-stretch"
              cols="12"
              lg="4"
              md="6"
              sm="6"
            >
              <div
                class="
                  w-100
                  justify-content-sm-center
                  justify-content-xl-start
                  justify-content-md-start
                "
              >
                <div class="mt-2">
                  <b-form-select
                    id="select_perpage"
                    v-model="pageSize"
                    :options="options"
                    @change="handlePageSizeChange($event)"
                  ></b-form-select>
                </div>
              </div>
            </b-col>
  
            <b-col
              class="d-flex align-items-stretch"
              cols="12"
              lg="8"
              md="12"
              sm="12"
            >
              <div class="w-100 p-2">
                <b-form-input
                  v-model="search"
                  id="search"
                  type="text"
                  placeholder="Search here"
                  v-on:keyup="key_search()"
                ></b-form-input>
              </div>
            </b-col>
          </b-row>
        </div>
        <br /> -->
        <div>
          <b-table
            :busy="load"
            :fields="fields"
            :items="customers"
            responsive
            hover
            bordered
            show-empty
            empty-text="No Data Available"
             class="text-center"
          >
            <template #table-busy>
              <b-skeleton-table
                v-if="load"
                :rows="10"
                :columns="6"
                :hide-header="true"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </template>
  
            <!-- <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          -->
            <template #cell(action)="data">
              <router-link title="View" :to="'/Customer/view/' + data.item.Customer_id">
                <i class="fas fa-eye fa-lg text-dark"></i>
              </router-link>
            </template>
          </b-table>
          <b-row align-v="center">
            <b-col cols="4">
              <span
                >Showing <b>{{ totalRows }}</b> out of
                <b>{{ totalData }}</b> entries.</span
              >
            </b-col>
            <b-col cols="8">
              <b-pagination
                v-model="page"
                :total-rows="count"
                :per-page="pageSize"
                @change="handlePageChange"
                prev-text="Prev"
                next-text="Next"
                align="right"
              ></b-pagination>
            </b-col>
          </b-row>
        </div>
        <!-- chart -->
        <hr />
        <br />
    </b-card>
    <b-card class="mb-4 w-100">
        <div>
          <div>
            <b-row>
              <b-col
                class="d-flex align-items-stretch"
                cols="12"
                lg="12"
                md="12"
                sm="12"
              >
                <b-row class="w-100">
                  <b-col
                    class="d-flex align-items-stretch"
                    cols="12"
                    lg="6"
                    md="6"
                    sm="6"
                  >
                    <div
                      class="
                        w-100
                        p-3
                        d-flex
                        justify-content-lg-end
                        justify-content-sm-center
                        justify-content-xl-end
                        justify-content-md-end
                      "
                    >
                      <h4>{{ this.groupby_customers_message }}</h4>
                    </div>
                  </b-col>
                  <b-col
                    class="d-flex align-items-stretch"
                    cols="12"
                    lg="6"
                    md="6"
                    sm="6"
                  >
                    <div
                      class="
                        w-100
                        p-3
                        d-flex
                        justify-content-lg-end
                        justify-content-sm-center
                        justify-content-xl-end
                        justify-content-md-end
                      "
                    >
                      <label class="fw-medium mb-0" for="lname2"
                        >Group customers by:
                      </label>
                    </div>
                    <div class="w-50 p-2">
                      <b-form-select
                        v-model="groupby_customer_selected"
                        :options="groupby_customer_options"
                        @change="handleGroupbyCustomerChange($event)"
                      ></b-form-select>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col lg="12" cols="12" md="12" class="d-flex align-items-stretch">
              <b-card class="mb-4 w-100">
                <div class="mt-4">
                  <vue-apex-charts
                    ref="groupby_customers_ref"
                    type="bar"
                    height="200"
                    :options="groupby_customer_bar_chart.chartOptions"
                    :series="groupby_customer_bar_chart.series"
                  ></vue-apex-charts>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </div>
        <hr />
        <br />
        </b-card>
        <b-card class="mb-4 w-100">
        <div>
          <div>
            <b-row>
              <b-col
                class="d-flex align-items-stretch"
                cols="12"
                lg="12"
                md="12"
                sm="12"
              >
                <b-row class="w-100">
                  <b-col
                    class="d-flex align-items-stretch"
                    cols="12"
                    lg="6"
                    md="6"
                    sm="6"
                  >
                    <div
                      class="
                        w-100
                        p-3
                        d-flex
                        justify-content-lg-end
                        justify-content-sm-center
                        justify-content-xl-end
                        justify-content-md-end
                      "
                    >
                      <h4>{{ this.locationwise_customers_message }}</h4>
                    </div>
                  </b-col>
                  <b-col
                    class="d-flex align-items-stretch"
                    cols="12"
                    lg="6"
                    md="6"
                    sm="6"
                  >
                    <div
                      class="
                        w-100
                        p-3
                        d-flex
                        justify-content-lg-end
                        justify-content-sm-center
                        justify-content-xl-end
                        justify-content-md-end
                      "
                    >
                      <label class="fw-medium mb-0" for="lname2"
                        >Location wise customer:
                      </label>
                    </div>
                    <div class="w-50 p-2">
                      <b-form-select
                        v-model="locationwise_customer_selected"
                        :options="locationwise_customer_options"
                        @change="handleLocationwiseCustomerChange($event)"
                      ></b-form-select>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col lg="12" cols="12" md="12" class="d-flex align-items-stretch">
              <b-card class="mb-4 w-100">
                <div class="mt-4">
                  <vue-apex-charts
                    ref="locationwise_customers_ref"
                    type="bar"
                    height="200"
                    :options="locationwise_customer_bar_chart.chartOptions"
                    :series="locationwise_customer_bar_chart.series"
                  ></vue-apex-charts>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </div>
        <br />
      </b-card>
  
  
      <!-- Customer Create  -->
  
  <b-modal id="modal-tall" title="Add Customer" 
  size="lg" header-bg-variant="primary" header-text-variant="light" ok-title="Save" 
  @ok="handleOk"
  :no-close-on-backdrop="true"
  >
  <b-form>
          <b-row>
            <b-col cols="6">
              <b-row class="py-3 align-items-center">
                <b-col cols="4"
                  ><label class="fw-medium mb-0" for="fname"
                    >Full Name<span class="text-danger">*</span> :</label
                  ></b-col
                >
                <b-col cols="7">
                  <b-form-input
                    name="fname"
                    :state="validateState('first_name')"
                    v-model="vendorForm.first_name"
                    type="text"
                    placeholder="Enter first name"
                    aria-describedby="first_name-1-live-feedback"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback id="first_name-1-live-feedback">
                    <span v-if="!this.$v.vendorForm.first_name.required">
                      Please enter first name.</span
                    >
                    <span
                      v-if="
                        this.$v.vendorForm.first_name.required &&
                        !this.$v.vendorForm.first_name.sameAsPassword
                      "
                      >The first name must be in alphabet.</span
                    >
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </b-col>
  
            <!-- <b-col cols="6">
              <b-row class="py-3 align-items-center">
                <b-col cols="4"
                  ><label class="fw-medium mb-0" for="fname"
                    >Last Name<span class="text-danger">*</span> :</label
                  ></b-col
                >
                <b-col cols="7">
                  <b-form-input
                    name="lname"
                    :state="validateState('last_name')"
                    v-model="vendorForm.last_name"
                    type="text"
                    placeholder="Enter last name"
                    aria-describedby="last_name-1-live-feedback"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback id="last_name-1-live-feedback">
                    <span v-if="!this.$v.vendorForm.last_name.required">
                      Please enter last name.</span
                    >
                    <span
                      v-if="
                        this.$v.vendorForm.last_name.required &&
                        !this.$v.vendorForm.last_name.sameAsPassword
                      "
                      >The last name must be in alphabet.</span
                    >
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </b-col>-->
          </b-row> 
          <b-row>
            <b-col cols="6">
              <b-row class="py-3 align-items-center">
                <b-col cols="4"
                  ><label class="fw-medium mb-0" for="email"
                    >Email<span class="text-danger">*</span> :</label
                  ></b-col
                >
                <b-col cols="7">
                  <b-form-input
                    name="email"
                    :state="validateState('email')"
                    v-model="vendorForm.email"
                    type="email"
                    aria-describedby="email-1-live-feedback"
                    placeholder="Enter email"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback id="email-1-live-feedback">
                    <span v-if="!this.$v.vendorForm.email.required">
                      Please enter email.</span
                    >
                    <span
                      v-if="
                        this.$v.vendorForm.email.required &&
                        !this.$v.vendorForm.email.email
                      "
                      >Please enter valid email.</span
                    >
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row class="py-3 align-items-center">
                <b-col cols="4"
                  ><label class="fw-medium mb-0" for="conact"
                    >Phone number<span class="text-danger">*</span> :</label
                  ></b-col
                >
                <b-col cols="7">
                  <b-form-input
                    name="contact_no"
                    :state="validateState('contact')"
                    v-model="vendorForm.contact"
                    type="text"
                    placeholder="Enter phone number"
                    aria-describedby="contact-1-live-feedback"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback id="contact-1-live-feedback">
                    <span v-if="!this.$v.vendorForm.contact.required">
                      Please enter phone number.</span
                    >
                    <span
                      v-if="
                        this.$v.vendorForm.contact.required &&
                        !this.$v.vendorForm.contact.numeric
                      "
                      >The phone number number must be numeric.</span
                    >
                    <span
                      v-if="
                        (this.$v.vendorForm.contact.required &&
                          this.$v.vendorForm.contact.numeric &&
                          !this.$v.vendorForm.contact.minLength) ||
                        !this.$v.vendorForm.contact.maxLength
                      "
                      >The phone number number must be in 10 digit.</span
                    >
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-row class="py-3 align-items-center">
                <b-col cols="4"
                  ><label class="fw-medium mb-0" for="companyName"
                    >Pincode<span class="text-danger">*</span> :</label
                  ></b-col
                >
                <b-col cols="7">
                  <b-form-input
                    name="pincode"
                    aria-describedby="pincode-1-live-feedback"
                    :state="validateState('pincode')"
                    v-model="vendorForm.pincode"
                    type="number"
                    placeholder="Enter pincode "
                    @input="pincodeCheck"
                  ></b-form-input>
                  <b-form-invalid-feedback id="company_name-1-live-feedback">
                    Please enter pincode .
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </b-col>
             <b-col cols="6">
              <b-row class="py-3 align-items-center">
                <b-col cols="4"
                  ><label class="fw-medium mb-0" for="city"
                    >City<span class="text-danger">*</span> :</label
                  ></b-col
                >
                <b-col cols="7">
                  <b-form-input
                    name="city"
                    aria-describedby="city-1-live-feedback"
                    v-model="vendorForm.city"
                    placeholder="Enter city "
                    readonly
                  ></b-form-input>
                  
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row class="py-3 align-items-center">
                <b-col cols="4"
                  ><label class="fw-medium mb-0" for="State"
                    >State<span class="text-danger">*</span> :</label
                  ></b-col
                >
                <b-col cols="7">
                  <b-form-input
                    name="State"
                    aria-describedby="State-1-live-feedback"
                    v-model="vendorForm.state"
                    placeholder="Enter State "
                    readonly
                  ></b-form-input>
                  
                </b-col>
              </b-row>
            </b-col> 
            <b-col cols="6">
              <b-row class="py-3 align-items-center">
                <b-col cols="4"
                  ><label class="fw-medium mb-0" for="Country"
                    >Country<span class="text-danger">*</span> :</label
                  ></b-col
                >
                <b-col cols="7">
                  <b-form-input
                    name="Country"
                    aria-describedby="Country-1-live-feedback"
                    v-model="vendorForm.country"
                    placeholder="Enter Country "
                    readonly
                  ></b-form-input>
                  
                </b-col>
              </b-row>
            </b-col> 
            <b-col cols="6">
              <b-row class="py-3 ">
                <b-col cols="4"
                  ><label class="fw-medium mb-0" for="address"
                    >Address :</label
                  ></b-col
                >
                <b-col cols="7">
                  <b-form-textarea
                    name="address"
                    aria-describedby="address-1-live-feedback"
                    v-model="vendorForm.address"
                    placeholder="Enter address "
                    rows="4"
                    :state="validateState('address')"
                  ></b-form-textarea>
                  <b-form-invalid-feedback id="company_name-1-live-feedback">
                    Please enter address .
                  </b-form-invalid-feedback>
                  
                </b-col>
              </b-row>
            </b-col>
             <b-col cols="6">
              <b-row class="py-3 ">
                <b-col cols="4"
                  ><label class="fw-medium mb-0" for="note"
                    >Note :</label
                  ></b-col
                >
                <b-col cols="7">
                  <b-form-textarea
                    name="note"
                    aria-describedby="note-1-live-feedback"
                    v-model="vendorForm.note"
                    placeholder="Enter note "
                    rows="4"
                  ></b-form-textarea>
                  
                </b-col>
              </b-row>
            </b-col>
          
          </b-row>
          <b-row>
           
            <b-col cols="6">
             
            </b-col>
          </b-row>
          <b-row>
            
          </b-row>
          <b-row>
          
          </b-row>
        </b-form>
  </b-modal>
      <!-- Customer Create  -->
    </div>
  </template>
  <script>
  import axios from "axios";
  import Multiselect from "vue-multiselect";
  import VueApexCharts from "vue-apexcharts";
  import { validationMixin } from "vuelidate";
  import {
    required,
    //sameAs,
    //alpha,
    email,
    numeric,
    minLength,
    maxLength,
    //helpers
  } from "vuelidate/lib/validators";
  export default {
    name: "managecustomers",
    mixins: [validationMixin],
    data: () => ({
      title: "managecustomers",
      num: 1,
      vendorForm: {
        first_name: "",
        last_name: "",
        email: "",
        contact: "",
        address: "",
        company_name: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
        gst_tin: "",
        pan: "",
        password: "",
        confirm_password: "",
      },
      groupby_customer_selected: "Monthly",
      groupby_customer_options: ["Daily", "Monthly", "Yearly"],
      groupby_customers_message: "Monthly Customers",
      groupby_customer_bar_chart: {
        series: [
          {
            data: [],
            name: "Customers",
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            height: 430,
          },
          plotOptions: {
            bar: {
              vertical: true,
              dataLabels: {
                position: "top",
              },
            },
          },
          dataLabels: {
            enabled: true,
            offsetY: 0,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"],
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          xaxis: {
            categories: [],
          },
        },
      },
  
      locationwise_customer_selected: "State",
      locationwise_customer_options: ["State", "City"],
      locationwise_customers_message: "State Wise Customers",
      locationwise_customer_bar_chart: {
        series: [
          {
            data: [],
            name: "Customers",
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            height: 430,
          },
          plotOptions: {
            bar: {
              vertical: true,
              dataLabels: {
                position: "top",
              },
            },
          },
          dataLabels: {
            enabled: true,
            offsetY: 0,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"],
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          xaxis: {
            categories: [],
          },
        },
      },
  
      // Month Table
      fields: [
        //  'index',
        {
          key: "Customer_id",
          label: "Customer id",
          sortable: true,
        },
        {
          key: "last_name",
          label: "name",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "phone",
          label: "Contact No.",
        },
        {
          key: "created_by",
          label: "Created By",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      item: [],
      value: "",
      first_name_selected: "",
      email_selected: "",
      phone_selected: "",
      customers: [],
      options: [10, 20, 30, 50],
  
      pageSize: 10,
      show: false,
      load: true,
      filter: "",
      search: "",
      CurrentPage: 1,
      sortBy: "created_at",
  
      page: 1,
      count: 0,
      totalData: "",
      totalRows: "",
  
      PerPage: "50",
  
      first_name_option: [],
      email_option: [],
      phone_option: [],
    }),
    validations: {
      vendorForm: {
        first_name: { required },
        email: { required, email },
        address: { required },
        contact: {
          required,
          numeric,
          minLength: minLength(10),
          maxLength: maxLength(10),
        },
       // password: { required, minLength: minLength(8), passwordCheck },
       // confirm_password: { required, sameAsPassword: sameAs("password") },
       // pan: { panCardNumberCheck },
       // gst_tin: {gstNumberCheck},
        pincode: { required,numeric, minLength: minLength(6), maxLength: maxLength(6) },
      },
    },
    components: { Multiselect, VueApexCharts },
    methods: {
      handlePageChange(value) {
        this.page = value;
        this.load = true;
        this.customersListing();
      },
      handlePageSizeChange(event) {
        // console.log(event.target.value);
        this.pageSize = event;
        this.load = true;
        this.page = 1;
        this.customersListing();
      },
      sortChanged(e) {
        this.sortBy = e.sortBy;
        this.load = true;
        this.customersListing();
      },
      customersListing() {
        this.load = true;
        // this.customers = [];
        var keyword = this.search;
        const params = this.getRequestParams(
          this.page,
          this.pageSize,
          this.sortBy
        );
        axios
          .post("/admin/get_customers", {
            params,
            search_keyword: keyword,
            email: this.email_selected,
            first_name: this.first_name_selected,
            phone: this.phone_selected,
          })
          .then((resp) => {
            if (resp.data.status_code == 200) {
              this.customers = [];
              if (resp.data.status) {
                this.count = this.totalData = resp.data.total_records;
                this.totalRows = resp.data.customers.length;
  
                for (var i = 0; i < resp.data.customers.length; i++) {
                  this.customers.push({
                    id: resp.data.customers[i].id,
                    Customer_id: resp.data.customers[i].customer_id,
                    first_name: resp.data.customers[i].name,
                    last_name: resp.data.customers[i].name,
                    email: resp.data.customers[i].email,
                    phone: resp.data.customers[i].phone,
                    created_by: resp.data.customers[i].created_by,
                  });
                }
                this.show = false;
                this.load = false;
              } else {
                this.count = this.totalData = resp.data.total_records;
                this.totalRows = resp.data.customers.length;
  
                this.show = false;
                this.load = false;
              }
            }
          });
      },
      getRequestParams(page, pageSize, sortBy = "created_at") {
        let params = {};
        if (page) {
          params["page"] = page - 1;
        }
        if (pageSize) {
          params["size"] = pageSize;
        }
        params["sortBy"] = sortBy;
        return params;
      },
      searchDropdownList() {
        axios.get("/admin/customer_search_lists").then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status) {
              this.first_name_option = resp.data.first_names;
              this.email_option = resp.data.emails;
              this.phone_option = resp.data.phones;
            }
          }
        });
      },
      applyFilter() {
        const value = "1";
        this.handlePageChange(value);
      },
      resetFilter() {
        this.email_selected = "";
        this.first_name_selected = "";
        this.phone_selected = "";
        this.search = "";
        this.customersListing();
      },
      ExportCustomers() {
        var keyword = this.search;
        const params = this.getRequestParams(
          this.page,
          this.pageSize,
          this.sortBy
        );
        axios
          .post(
            "/admin/customer_pdf_export",
            {
              params,
              search_keyword: keyword,
              email: this.email_selected,
              first_name: this.first_name_selected,
              phone: this.phone_selected,
            },
            {
              responseType: "blob",
              // responseType: 'arraybuffer'
            }
          )
          .then((resp) => {
            // let blob = new Blob([resp.data], { type: 'application/pdf' } ),
            // url = window.URL.createObjectURL(blob)
            // window.open(url);
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "customer_lists.pdf");
            document.body.appendChild(link);
            link.click();
          });
      },
      key_search() {
        const value = "1";
        this.handlePageChange(value);
      },
      handleGroupbyCustomerChange(value) {
        axios
          .post("/admin/get_groupby_customers_count", {
            selected_type: value,
          })
          .then((resp) => {
            if (resp.data.status_code == 200) {
              if (resp.data.status) {
                this.groupby_customers_message =
                  resp.data.groupby_customers_message;
                // console.table(this.groupby_customer_bar_chart.chartOptions.xaxis.categories);
                this.$refs.groupby_customers_ref.refresh(
                  (this.groupby_customer_bar_chart.series[0].data =
                    resp.data.total_customers),
                  (this.groupby_customer_bar_chart.chartOptions.xaxis.categories =
                    resp.data.series_data_arr)
                );
              }
            }
          });
      },
      handleLocationwiseCustomerChange(value) {
        axios
          .post("/admin/get_locationwise_customers_count", {
            selected_type: value,
          })
          .then((resp) => {
            if (resp.data.status_code == 200) {
              if (resp.data.status) {
                this.locationwise_customers_message =
                  resp.data.locationwise_customers_message;
                // console.table(this.groupby_customer_bar_chart.chartOptions.xaxis.categories);
                this.$refs.locationwise_customers_ref.refresh(
                  (this.locationwise_customer_bar_chart.series[0].data =
                    resp.data.total_customers),
                  (this.locationwise_customer_bar_chart.chartOptions.xaxis.categories =
                    resp.data.series_data_arr)
                );
              }
            }
          });
      },
      validateState(name) {
        const { $dirty, $error } = this.$v.vendorForm[name];
        return $dirty ? !$error : null;
      },
      handleOk(bvModalEvent) {
        bvModalEvent.preventDefault();
  
        this.$v.vendorForm.$touch();
        if (this.$v.vendorForm.$anyError) {
      
          return;
        } else {
          this.handleSubmit();
        }
      },
        handleSubmit() {
          
          if (!this.$v.vendorForm.$anyError) {
          axios
            .post("/admin/register_customer", {
              first_name: this.vendorForm.first_name,
              email: this.vendorForm.email,
              phone: this.vendorForm.contact,
              country: this.vendorForm.country,
              city: this.vendorForm.city,
              address: this.vendorForm.address,
              state: this.vendorForm.state,
              note: this.vendorForm.note,
              pincode: this.vendorForm.pincode,
              password:'Admin@123',
              password_confirm:'Admin@123',
            })
            .then((resp) => {
              if (resp.data.status) {
                this.close();
                this.customersListing();
                this.$root.$refs.app.showToast("success", resp.data.message);
              } else {
                this.$root.$refs.app.showToast("danger", resp.data.message);
              }
            });
        }
        },
        close() {
        this.$nextTick(() => {
          this.$bvModal.hide("modal-tall");
          this.vendorForm = {
            first_name: "",
            last_name: "",
            email: "",
            contact: "",
            address: "",
            company_name: "",
            city: "",
            state: "",
            pincode: "",
            gst_tin: "",
            pan: "",
            country: "",
          };
          this.$v.$reset();
        });
      },
        pincodeCheck()
        {
          const str=this.vendorForm.pincode;
          axios
                  .post("/admin/pincodeCheck", {
                      pincode: str
                  })
                  .then((resp) => {
                      if (resp.data.pincode_city==0) {
                        this.vendorForm.city="";
                        this.vendorForm.state="";
                        this.vendorForm.country="";
                      }
                      else
                      {
      this.vendorForm.city=resp.data.pincode_city.division;
                      this.vendorForm.state=resp.data.pincode_city.state;
                      this.vendorForm.country=resp.data.pincode_city.country;
                       
                      }
                  });
        },
    },
  
    mounted() {
      this.handleGroupbyCustomerChange(this.groupby_customer_selected);
      this.handleLocationwiseCustomerChange(this.locationwise_customer_selected);
      this.customersListing();
      this.searchDropdownList();
      this.show = true;
    },
  };
  </script>
    <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
    <style scoped>
  #search {
    max-width: 280px;
  }
  </style>